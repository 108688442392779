// Options
$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: false;

//Paddings
$default-margin: 15px;


// Color system
$theme-colors: ("primary": #003C21,
  "secondary": #F0F0F0,
  "white": #FFFFFF);
  $body-color: #000;

// Typography

$font-size-base: 1.6rem;
$font-family-sans-serif: "Open Sans";
$fa-font-path: '/files/assets/dist/fonts/fontawesome/';

// Slick

$slick-font-path: "/files/assets/dist/fonts/slick/" !default;
$slick-loader-path: "/files/assets/dist/img/" !default;

$slick-font-family: 'fontawesome';
$slick-arrow-color: #fff;
$slick-prev-character: "\f053";
$slick-next-character: "\f054";
$slick-dot-character: "\f111";
$slick-dot-size: 1.2rem;

// Navbar

$navbar-padding-y: 0;
$navbar-nav-link-padding-x: $default-margin;

// Navs

$nav-link-padding-y:                1.5rem;

// Breadcrumbs
$breadcrumb-padding-y: 0;
$breadcrumb-bg: transparent;
