@import 'variables';

@import '../../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../../node_modules/slick-carousel/slick/slick.scss';
@import '../../../node_modules/slick-carousel/slick/slick-theme.scss';

@import '../../../node_modules/font-awesome/scss/font-awesome.scss';

@import 'fonts';
// @import 'navbar';
// @import 'pageimage';
// @import 'topbar';
// @import 'block-header';
// @import 'images';
// @import 'profile';
// @import 'footer';


/**
Basics
*************************************************************/

.cc_dialog.headline {
    position: fixed !important;
}

html {
    font-size: 62.5%;

    height: 100%;
}

body {
    line-height: 2.2rem;

    height: 100%;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
    outline: none !important;
}

.invisible {
    display: none;
}

h1,
.h1 {
    font-size: 2.7rem;
    font-weight: bold;
    font-style: normal;
    line-height: 3.4rem;

    margin-bottom: 3.4rem;

    @include media-breakpoint-down(md) {
        font-size: 2.2rem;
        line-height: 3rem;
        margin-bottom: 2rem;
    }
}

h2,
.h2 {
    font-size: 1.9rem;
    font-weight: 800;
    line-height: 3.2rem;

    text-transform: uppercase;

    @include media-breakpoint-down(md) {
        font-size: 1.8rem;
        line-height: 2rem;
        margin-bottom: 1.5rem;
    }

}

h3,
.h3 {
    font-size: 1.6rem;
}

div[class*=' col-'] {
    p:last-of-type {
        margin-bottom: 0;
    }
}

figure {
    margin-bottom: 0;
}

.btn {
    font-size: 1.4rem;

    padding: 1.5rem 3.8rem;

    text-transform: uppercase;

    &.btn-primary {
        border: none;

        &:hover {
            background: darken(map-get($map: $theme-colors, $key: 'primary'), 2%);
        }
    }

    &.btn-outline-white {
        &:hover {
            color: #fff;
            background: darken(map-get($map: $theme-colors, $key: 'primary'), 2%);
        }
    }
}

/**
Navigation
*************************************************************/

#navigation {
    position: relative;
    z-index: 20;

    .navbar {
        .navbar-brand {
            width: 106px;
            height: 167px;
            margin: 0 95px -85px 95px;
            padding: 43px 10px 24px 10px;

            background-color: map-get($map: $theme-colors, $key: 'primary');
            z-index: 10;

            @include media-breakpoint-down(md) {
                padding: 10px;
                margin: 0 0 -30px 5px;

                width: 80px;
                height: auto;
            }
        }

        &.custom-toggler .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 60, 33)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }

        &.custom-toggler .navbar-toggler {
            // border-color: map-get($map: $theme-colors, $key: 'primary');
            border: none;
            padding: 5px 0;
        }

        .nav-link {
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 2rem;

            letter-spacing: .12rem;
            text-transform: uppercase;

            color: $body-color;

            margin-left: 65px;
            white-space: nowrap;

            &:hover {
                color: map-get($map: $theme-colors, $key: 'primary');
            }

            @include media-breakpoint-down(md) {
                margin-left: 5px;
            }
        }

        .navbar-nav {
            @include media-breakpoint-down(md) {
                margin-top: 40px;
            }
        }
    }

    // .navbar-nav,
    // .mr-auto {
    //     flex: 1;
    //     margin: auto !important;
    //     display: flex;
    //     justify-content: space-around;
    // }
}

/**
Kopfbereich
*************************************************************/

#header {
    margin: 82px 0 65px 0;

    @include media-breakpoint-down(md) {
        margin: 57px 0 30px 0;
    }

    figure {
        margin: 0;

        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }

    h1,
    .h1 {
        font-size: 2.2rem;
        line-height: 2.6rem;

        margin-bottom: 1.6rem;
    }

    p {
        line-height: 2rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .ce_text {
        padding: 30px;

        background: #fff;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}


/**
Content
*************************************************************/

.mod_article {
    margin-bottom: 65px;

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
    }
}

.angebot {

    @include media-breakpoint-down(xs) {
        margin-bottom: 0;
    }

    .container {
        @include media-breakpoint-down(xs) {
            padding: 0;
        }
    }

    .ce_text {
        font-size: 1.4rem;
        line-height: 2rem;

        padding: 30px;

        @include media-breakpoint-down(xs) {
            padding: 30px 15px;
        }

        color: #fff;
        background-color: map-get($map: $theme-colors, $key: 'primary');

        h2,
        .h2 {
            font-size: 2.2rem;
            font-weight: 600;
            line-height: 2.6rem;

            margin-bottom: 30px;

            text-transform: none;
        }

        .btn {
            margin-top: 30px;
        }
    }

    figure {
        margin-bottom: 0;
    }
}

.sortiment_preview {
    padding: 120px 0 85px 0;

    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }

    @include media-breakpoint-down(xs) {
        margin-bottom: 0;
    }

    .ce_image {
        .image_container {
            margin-bottom: 35px;

            text-align: center;

            box-shadow: 0 0 15px rgba(0, 0, 0, .15);

            .caption {
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 2.2rem;

                position: relative;
                z-index: 10;

                display: block;

                margin: 0 30px;
                padding: 10px 10px;

                background: rgba(255, 255, 255, .8);

                @include media-breakpoint-down(md) {
                    font-size: 1.6rem;
                    margin: 0 15px;
                    padding: 5px;
                }
            }
        }
    }
}

.marken {
    .ce_slick-slider {
        margin: 65px 0;

        .image_container {
            margin-bottom: 0;

            img {
                width: auto;
                max-height: 50px;
                margin: 0 auto;
            }
        }
    }
}

.oeffnungszeiten {
    font-size: 1.9rem;
    line-height: 3.2rem;

    h2 {
        margin-bottom: 3.2rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 1.5rem;
        }
    }

    @include media-breakpoint-down(md) {
        margin-top: 30px;
    }
}

.sortiment {

    &.mod_article {
        margin: 0;
        padding: 65px 0;

        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }

        &.last {
            @include media-breakpoint-up(md) {
                margin-bottom: 65px !important;
            }
        }
    }

    .ce_text {

        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }

        .inner {
            padding: 30px;

            font-size: 1.4rem;
            line-height: 2rem;

            &.bg-primary {
                color: #FFFFFF;
            }

            h2,
            .h2 {
                font-weight: 600;
                text-transform: none;
            }
        }
    }



    .ce_slick-slider {

        .slick-next,
        .slick-prev {
            background: map-get($map: $theme-colors, $key: 'primary');
            color: #FFFFFF;
            width: 50px;
            height: 50px;
            z-index: 10;
            top: 25px;
            opacity: .5;

            transition: opacity .15s ease-in-out;

            &::before {
                opacity: 1;
            }

            &:hover {
                opacity: 1;
            }
        }

        .slick-prev {
            left: 15px;
        }

        .slick-next {
            right: 15px;
        }
    }

    .image_container {
        margin-bottom: 0;
    }
}


/**
Footer
*************************************************************/

#footer {
    border-bottom: 10px solid map-get($map: $theme-colors, $key: 'primary');

    .row.social {
        min-height: 250px;
        margin-bottom: 65px;

        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }

        .social_bg {
            background-image: url('/files/assets/dist/img/social_bg.jpg');
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            margin: 0 15px;

            @include media-breakpoint-down(xs) {
                margin: 30px 0;
            }
        }

        .social_slogan {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        a {
            position: relative;
            top: 87px;

            @include media-breakpoint-down(xs) {
                top: 57px;
            }

            display: block;

            height: 77px;

            svg {

                .inner,
                .outer {
                    transition: fill .15s ease-in-out;
                }
            }

            &:hover {
                svg {
                    .inner {
                        fill: #fff;
                    }

                    .outer {
                        fill: #003c21;
                    }
                }
            }
        }
    }

    .row.contact {
        margin-bottom: 65px;

        iframe {
            height: 100%;

            @include media-breakpoint-down(lg) {
                margin-bottom: 35px;
            }
        }

        .oeffnungszeiten {
            @include media-breakpoint-down(md) {
                margin-top: 0;
            }

            h2 {
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0;
            }
        }

        .bg-primary {
            margin: 30px 0;
            padding: 30px;

            color: #fff;

            a {
                color: #fff;

                &:hover {
                    text-decoration: underline;

                    color: #fff;
                }
            }

            address {
                font-size: 2.2rem;
                font-weight: 600;
                line-height: 2.6rem;
            }

            p {
                margin-bottom: 0;
            }
        }

        .dsgvo,
        .dsgvo a {
            font-size: 1.4rem;
            font-weight: 600;
            line-height: 2rem;

            letter-spacing: 1.2px;
            text-transform: uppercase;

            color: #000;
        }
    }
}

/**
Cookiebar
*************************************************************/

.cookiebar {
    background-color: #1b1b1b;
}
.cookiebar__text,
.cookiebar__message {
    text-align: left;
}

.cookiebar__button {
    background-color: map-get($map: $theme-colors, $key: 'primary');
}